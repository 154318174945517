import React from "react"
import styled from "styled-components"
import Layout from "@components/layout"
import { graphql } from "gatsby"
import ArticleCard from "@components/article/card"
import PropTypes from "prop-types"
import Html from "@components/html"

const ArticleTitle = styled.h1`
  font-size: 300%;
  text-align: left;
  color: ${({ theme }) => theme.color.indigo600};
`

const ArticleSubtitle = styled.h2`
  font-size: 175%;
  font-weight: 500;
  color: ${({ theme }) => theme.color.indigo600};
  margin-bottom: 4rem;
`

const ArticleGrid = styled.div`
  display: flex;
  flex-flow: column;
  gap: 4rem;
`

const ArticleContainer = styled.div`
  max-width: 850px;
  margin: 4rem auto;
`

const Articles = ({ data, pageContext }) => {
  return (
    <Layout canonical={pageContext.slug} seo={data.category.seo}>
      <ArticleContainer>
        <ArticleTitle>{data.category.name}</ArticleTitle>
        <ArticleSubtitle>
          <Html inherit parseElements html={data.category.description} />
        </ArticleSubtitle>
        <ArticleGrid>
          {data.articles.edges.map(({ node: article }) => {
            const date = new Date(article.date.replace(/-/g, "/"))
            const dateString = date.toLocaleDateString("en-US", {
              month: "long",
              day: "numeric",
              year: "numeric",
            })
            return (
              <ArticleCard
                key={article.id}
                image={article.image}
                title={article.seo.title}
                summary={article.seo.description}
                link={article.slug}
                author="Klipfolio"
                date={dateString}
              />
            )
          })}
        </ArticleGrid>
      </ArticleContainer>
    </Layout>
  )
}

Articles.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default Articles

export const pageQuery = graphql`
  query ArticleCategoryQuery($id: String!) {
    category: articleCategories(id: { eq: $id }) {
      seo {
        ...seoData
      }
      name
      heading
      description
      id
      slug
      status
    }
    articles: allArticles(
      filter: { status: { eq: "published" }, category: { id: { eq: $id } } }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          seo {
            ...seoData
          }
          id
          status
          date
          slug
          image {
            id
            title
            cdn
            placeholder
          }
        }
      }
    }
  }
`
